import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Your gift is a `}<a href="https://arkmuntasser.github.io/to-becks/letter/christmas-2016/" data-text="totally sweet animation">{`totally sweet animation`}</a>{` I made! Haha, not really... or is it?`}</p>
    <p>{`Merry Christmas, my love! I can’t believe it’s already been over two years; it feels like we were having our first date just the other day and at the same time it feels like we’ve always been together. Everyday with you is a wonderful adventure even if that adventure is just us sitting on the couch watching cartoons. You’re my best friend and I can’t imagine my life without you.`}</p>
    <p>{`I’m in constant awe of you; your ambition, creativity, humor, patience, and empathy are just some of qualities I admire so much about you. You make every life you touch better and I know this to be true because you’ve made my life better; you’ve made me better.`}</p>
    <p>{`I hope to earn your love, respect, friendship, and partnership everyday from now until forever. I can’t wait to spend the rest of my life with you, Becks.`}</p>
    <p className="signature">
	<span>&mdash;</span> Amir
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      